<template>
  <div class="model page">
    <section class="section">
      <h1 class="title">profile</h1>

      <div class="card">
        <!--div class="card-image">
          <figure class="image is-4by3">
            <img :src="user.photoURL" alt="Placeholder image" />
          </figure>
        </div-->
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img :src="user.photoURL" alt="Placeholder image" />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{{ user.displayName }}</p>
              <p class="subtitle is-6">{{ user.email }}</p>
            </div>
          </div>

          <div class="content">
            <!-- 

                  CLIENT

            -->
            <span v-if="is_client">
              <p>
                <span v-if="subscription_status">Active.</span>
                Modify your subscription here :
              </p>
              <a :href="stripe_link" target="_blank">
                <button class="black-button">SUBSCRIPTION MANAGEMENT</button>
              </a>
            </span>
            <!-- 

              NEW USER

            -->
            <span v-else>
              <!-- <pre>{{ products }}</pre> -->
              <p>
                You need to subscribe to this service, please choose your
                subscription :
              </p>
              <!-- 

                PRODUCT : visual (data_product.product.stripe_metadata_module)

              -->
              <div
                v-for="(data_product, id) in products"
                class=""
                :key="data_product.product_id"
              >
                <div
                  :data-id="id"
                  class="product__block product__unit"
                  v-if="
                    data_product.product.active &&
                    data_product.product.stripe_metadata_module == 'visual'
                  "
                >
                  <h2>{{ data_product.product.name }}</h2>
                  <p>{{ data_product.product.description }}</p>

                  <!-- 

                    PRICES

                  -->
                  <div
                    v-for="data_price in data_product.prices"
                    :key="data_price.price_id"
                    :data-id="data_price.price_id"
                    :class="{
                      product__price: true,
                      selected: selected_price == data_price.price_id,
                    }"
                    @click="selectPrice(data_price.price_id)"
                  >
                    <!-- <pre>{{ data_price }}</pre> -->

                    <b-button type="is-primary" outlined>
                      {{ data_price.price.unit_amount / 100 }} € /
                      {{ data_price.price.interval }}
                    </b-button>
                  </div>

                  <img :src="data_product.product.images" class="mt-3 mb-6" />
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <!--pre>{{ subscription_status }}*</pre>
      <pre>{{ client.ended_at }}*</pre>
      <pre>{{ client.role }}*</pre>
      <pre>{{ client.status }}*</pre>
      <pre>{{ client }}</pre-->
    </section>

    <abstraktion-footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase";
import * as fb from "../firestore";
// https://github.com/stripe/stripe-js#stripejs-es-module
import { loadStripe } from "@stripe/stripe-js";
import AbstraktionFooter from "../components/AbstraktionFooter.vue";

export default {
  name: "Profile",
  components: {
    AbstraktionFooter,
    //FilePickerButton,
  },
  //https://vuex.vuejs.org/guide/state.html#single-state-tree
  computed: {
    ...mapState([
      "user",
      "client",
      "stripe_link",
      "subscription_status",
      "is_client",
      "products",
      "prices",
    ]),
  },
  mounted() {
    //this.fetchProducts();
    //this.isClient();
  },
  data() {
    return {
      loaded: null,
      selected_price: null,
      //product: {},
      //is_client: false,
    };
  },
  methods: {
    selectPrice(price) {
      this.selected_price = price;
      //console.log( price )

      this.checkoutSession();
    },
    async getCustomClaimRole() {
      await firebase.auth().currentUser.getIdToken(true);
      const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
      return decodedToken.claims.stripeRole;
    },

    async checkoutSession() {
      let currentUser = firebase.auth().currentUser;

      const docRef = await fb.db
        .collection("customers")
        .doc(currentUser.uid)
        .collection("checkout_sessions")
        .add({
          price: this.selected_price,
          trial_from_plan: false,
          //allow_promotion_codes: true,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();

        //console.log('onSnapshot',snap,error,sessionId)

        if (error) {
          //console.log('error',error)
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          //alert(`An error occured: ${error.message}`)
        }
        if (sessionId) {
          //console.log('sessionId',sessionId)
          // We have a session, let's redirect to Checkout
          // Init Stripe

          const stripe = await loadStripe("pk_live_7GbH8kz2AArLNNFUXpOFzR8H");

          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../scss/main.scss";

.section {
  min-height: calc(100vh - 187px);
}

button {
  text-transform: uppercase;
}

.product {
  &__block {
    padding: 15px 0;
  }

  &__unit {
    margin-bottom: 15px;
  }

  &__price {
    margin: 8px 0;
    cursor: pointer;
    transition: all 1s;
    //opacity: .5;

    &:hover {
      //opacity: 1;
    }
    &.selected {
      font-weight: 900;
      opacity: 1;
    }
  }

  &__subscription {
    text-transform: uppercase;
    font-weight: 600;
  }
}
</style>